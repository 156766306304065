import React from 'react'
import { Helmet } from 'react-helmet'
import '../../styles/bestExpert.less'
import Logo from '../../images/logo.svg'
import padamamBG from './img/padamam.svg'
import { Video } from '../../components/ContestVideo'

import ScrollToTopOnMount from '../../helpers/scrollRestorationFix'

import bakanova from './img/bakanova.svg'
import lutohina from './img/lutohina.svg'
import vedrov from './img/vedrov.svg'
import matorina from './img/matorina.svg'
import mishukova from './img/mishukova.svg'
import gubochkina from './img/gubochkina.svg'
import morozova from './img/morozova.svg'
import lyahov from './img/lyahov.svg'
import grandPrize from './img/grandPrize.svg'
import medalStart from './img/medalStart.svg'
import medalSpeed from './img/medalSpeed.svg'

const participants = [
  {
    img: bakanova,
    name: 'Баканова Лилия',
    city: 'Москва',
    winner: true,
    grandPrize: grandPrize,
  },
  {
    img: lutohina,
    name: 'Наталия Лутохина',
    city: ' Санкт-Петербург',
    place: '1 место,',
    prizePlace: true,
  },
  {
    img: vedrov,
    name: 'Кирилл Ведров',
    city: 'Санкт-Петербург',
    place: `2 место`,
    special: `и\xa0спец. номинация от\xa0Абсолют Банка,`,
    prizePlace: true,
  },
  {
    img: matorina,
    name: 'Оксана Маторина',
    city: ' Москва',
    place: '3 место,',
    prizePlace: true,
  },
  {
    img: mishukova,
    name: 'Маргарита Машукова',
    city: ' Москва',
    place: '4 место,',
    prizePlace: true,
  },
  {
    img: gubochkina,
    name: 'Марина Губочкина',
    city: ' Москва',
    place: '5 место,',
    prizePlace: true,
  },
  {
    img: morozova,
    name: 'Морозова Светлана',
    city: 'Санкт-Петербург',
    fastDeal: true,
    medal: medalSpeed,
  },
  {
    img: matorina,
    name: 'Оксана Маторина',
    city: ' Москва',
    fastDeal: true,
    medal: medalSpeed,
  },
  {
    img: lyahov,
    name: 'Владимир Ляхов',
    city: ' Москва',
    successfulStart: true,
    medal: medalStart,
  },
]

const containerStyles = {
  maxWidth: 880,
  padding: '0 20px',
  margin: '0 auto',
}

const skolkovoStyles = {
  maxWidth: 700,
  display: 'inline-block',
  textAlign: 'left',
  padding: '25px 0',
}

const DESCRIPTION = 'Россия выбрала лучшего ипотечного эксперта'

const BestIpotekaExpert = () => {
  return (
    <>
      <Helmet>
        <title>Россия выбрала лучшего ипотечного эксперта</title>

        <meta name="description" content={DESCRIPTION} />
        <meta property="og:description" content={DESCRIPTION} />
      </Helmet>
      <ScrollToTopOnMount />
      <div style={containerStyles} className="skolkovo">
        <div className="skolkovo--header">
          <a href="/" target="_blank">
            <img src={Logo} alt={'logo'} />
          </a>
        </div>
        <div style={skolkovoStyles} className="skolkovo--content">
          <h1>Россия выбрала лучшего ипотечного эксперта</h1>
          <h3 className="three-years">
            В&nbsp;конце февраля состоялось награждение финалистов премии
            &laquo;Лучший ипотечный эксперт России 2020&raquo;. Это единственный
            ежегодный всероссийский конкурс для профессионалов ипотечного
            бизнеса. Премия учреждена в&nbsp;2019 году компанией Ипотека.Центр.
            Всего в&nbsp;конкурсе приняли участие 5000 специалистов со&nbsp;всей
            России. Победу одержали 9 лучших агентов ипотеки, один
            из&nbsp;которых стал обладателем суперприза
            в&nbsp;1&nbsp;000&nbsp;000&nbsp;рублей.
          </h3>

          <p className="standard-text">
            26&nbsp;февраля прошла церемония награждения победителей
            единственной всероссийской премии для профессионалов ипотеки
            &laquo;Лучший ипотечный эксперт России 2020&raquo;. В&nbsp;этом году
            учредитель и&nbsp;организатор конкурса компания Ипотека.Центр
            перезапустила торжественную церемонию в&nbsp;формате открытого
            реалити-шоу, в&nbsp;котором могли принять участие желающие
            по&nbsp;всей стране.
          </p>
        </div>

        <div className="translation-title">Победитель конкурса</div>

        <div className="prize-winner">
          <img className="prize-winner-img" src={participants[0].img} />
          <div className="prize-banner">
            <div className="prize-title">Суперприз</div>
            <div className="prize-sum">1 000 000 ₽</div>
            <div className="prize-winner-name">{participants[0].name}</div>
            <div className="prize-winner-city">{participants[0].city}</div>
          </div>
          <img className="prize-grand-prize" src={participants[0].grandPrize} />
        </div>

        <div className="prize-winner-mobile">
          <div className="prize-sum">Суперприз 1 000 000 ₽</div>
          <div className="prize-winner-container">
            <img className="prize-winner-img" src={participants[0].img} />
            <div className="prize-winner-name">{participants[0].name}</div>
            <div className="prize-winner-city">{participants[0].city}</div>
            <img
              className="prize-grand-prize"
              src={participants[0].grandPrize}
            />
          </div>
        </div>

        <div className="translation-title">Призовые места</div>

        <div className="prize-places">
          {participants
            .filter((participant) => participant.prizePlace)
            .map((participant, i) => (
              <div key={participant.name} className="prize-participant">
                <img src={participant.img} />
                <div className="participant-name">{participant.name}</div>
                <span className="participant-place">{participant.place}</span>
                {participant.special && (
                  <div className="participant-special">
                    {participant.special}
                  </div>
                )}
                {i !== 0 ? (
                  <span className="participant-city">{participant.city}</span>
                ) : (
                  <div className="participant-city">{participant.city}</div>
                )}
                {participant.medal && (
                  <img className="medal" src={participant.medal} />
                )}
              </div>
            ))}
        </div>

        <div className="translation-title">Специальные номинации</div>

        <div className="special-nomination">
          <div>
            <div className="violet-title">Самая быстрая сделка</div>
            <div className="participants-special-wrapper">
              {participants
                .filter((participant) => participant.fastDeal)
                .map((participant, i) => (
                  <div className="prize-participant">
                    <img src={participant.img} />
                    <div className="participant-name">{participant.name}</div>
                    <div className="participant-place">{participant.place}</div>
                    {participant.special && (
                      <div className="participant-special">
                        {participant.special}
                      </div>
                    )}
                    <div className="participant-city">{participant.city}</div>
                    <img
                      className={`medal ${i === 0 && 'noMedalMobile'}`}
                      src={participant.medal}
                    />
                  </div>
                ))}
            </div>
          </div>
          <div>
            <div className="violet-title">Успешный старт</div>
            <div className="participants-special-wrapper oneChildGrid">
              {participants
                .filter((participant) => participant.successfulStart)
                .map((participant) => (
                  <div className={`prize-participant`}>
                    <img src={participant.img} />
                    <div className="participant-name">{participant.name}</div>
                    <div className="participant-place">{participant.place}</div>
                    {participant.special && (
                      <div className="participant-special">
                        {participant.special}
                      </div>
                    )}
                    <div className="participant-city">{participant.city}</div>
                    <img className="medal" src={participant.medal} />
                  </div>
                ))}
            </div>
          </div>
        </div>

        <p className="standard-text">
          Звездным модератором мероприятия в&nbsp;этом году стал шоумен
          и&nbsp;телеведущий Айдар Гараев. Для участников и&nbsp;зрителей было
          подготовлено много сюрпризов. Розыгрыши главных победителей премии,
          первый в&nbsp;истории ипотечный рэп, рецепты успеха и&nbsp;коктейля
          настоящего ипотечника, а&nbsp;также призы и&nbsp;подарки для зрителей
          трансляции Премии.
        </p>

        <div className="translation">
          <div className="translation-title">Запись трансляции</div>
          <div className="conditions__video">
            <Video
              data={{
                attributes: {
                  src: 'https://youtu.be/IZmYhSM6O00',
                  image: padamamBG,
                },
              }}
              options={{
                gridTemplateColumns: '1fr',
                width: '100%',
                description: false,
                stat: false,
                videoWidth: '100%',
                videoHeight: '100%',
                playBtnWidth: '190px',
                playBtnHeight: '190px',
                titleInline: true,
              }}
            />
          </div>
        </div>

        <p className="standard-text">
          Информационные партнеры конкурса: Банки.ру, ЛюдиИпотеки, Национальный
          банковский журнал, TTFinance, Недвижа, Cottage, Urban, Гильдия
          риэлтеров Москвы.
        </p>

        <div className="skolkovo--footer">
          <p>
            <b>Справка</b>
          </p>
          <p>
            Ипотека.Центр&nbsp;&mdash; ипотечная&nbsp;IT компания, находящаяся
            на&nbsp;стыке облачных технологий и&nbsp;реального бизнеса. Компания
            берёт своё начало с&nbsp;августа 2017&nbsp;года. Является ТМ ООО
            &laquo;ФинМаркет&raquo;{' '}
            <a href="/" target="_blank">
              https://ipoteka.center
            </a>
          </p>
          <p>
            Платформа Ипотека.Центр&nbsp;&mdash; собственная российская
            разработка ООО &laquo;ФинМаркет&raquo;. Инновационный сервис для
            профессионалов ипотечного бизнеса, позволяет проводить полный цикл
            сделки по&nbsp;ипотечному кредиту онлайн и&nbsp;получать высокое
            агентское вознаграждение. Платформа была запущена в&nbsp;июне
            2019&nbsp;года.
          </p>
          <p>
            Контакты для прессы:{' '}
            <a href="mailto:pr@ipoteka.center">pr@ipoteka.center</a>
          </p>
        </div>
      </div>
    </>
  )
}

export default BestIpotekaExpert
